import React from "react";
import snoowrap from 'snoowrap'
import Sentiment from 'sentiment'
import Navbar from 'react-bootstrap/NavBar';
import Card from './Card'

import PieChart from './PieChart'
import BarChart from './BarChart'

import logo from './logo2.png';
import SearchDetails from './SearchDetails'
import UserSearchDetails from './UserSearchDetails'
import $ from 'jquery';
require('dotenv').config();

const App = () => {
  return (
    <div id='cont1'>
      <Search />
    </div>
  );
};

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subReddit: 'casualconversation', //for subreddit query
      postHierarchy: 'hot', //for subreddit query

      user: 'spez', //for user query
      numComments: 20, //for user query
      commentList: [], //for user query:list of user comments 
      viewSubCommentToggle: 'comments', //for user query

      numSubmissions: 30, //for both queries
      submissionList: [], // for both queries: list of subreddit or user submissions

      submissionSubject: 'subreddit' // default call is to casualconversations. 
    }
    this.handleAggregate = this.handleAggregate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitUser = this.handleSubmitUser.bind(this);
    this.newQuery = this.newQuery.bind(this);
    this.toggleUserCards = this.toggleUserCards.bind(this);
    this.showUserComments = this.showUserComments.bind(this);
  }

  componentDidMount() {
    this.newQuery()
  }

  newQuery() {
    const r = new snoowrap({

      userAgent: process.env.REACT_APP_USER_AGENT,
      clientId: process.env.REACT_APP_CLIENT_ID,
      clientSecret: process.env.REACT_APP_CLIENT_SECRET,
      refreshToken: process.env.REACT_APP_REFRESH_TOKEN,
    });
    const subReddit = this.state.subReddit
    const postHierarchy = this.state.postHierarchy
    const submissions = parseInt(this.state.numSubmissions)
    this.setState({
      submissionList: []
    })
    if (this.state.submissionSubject === 'subreddit') {
      if (postHierarchy === 'top') {
        r.getSubreddit(subReddit).getTop({ limit: submissions }).then((value) => {
          this.setState({ submissionList: value })
        });
        return
      }
      if (postHierarchy === 'hot') {
        r.getSubreddit(subReddit).getHot({ limit: submissions }).then((value) => {
          this.setState({ submissionList: value })
        });
        return
      }
      if (postHierarchy === 'new') {
        r.getSubreddit(subReddit).getNew({ limit: submissions }).then((value) => {
          this.setState({ submissionList: value })
        });
        return
      }
      if (postHierarchy === 'controversial') {
        r.getSubreddit(subReddit).getControversial({ limit: submissions }).then((value) => {
          this.setState({ submissionList: value })
        });
        return
      }
      if (postHierarchy === 'rising') {
        r.getSubreddit(subReddit).getRising({ limit: submissions }).then((value) => {
          this.setState({ submissionList: value })
        });
        return
      }
    }

    if (this.state.submissionSubject === 'user') {
      r.getUser(this.state.user).getComments({ limit: this.state.numComments }).then((value) => {
        this.setState({ commentList: value })
      });
      r.getUser(this.state.user).getSubmissions({ limit: this.state.numSubmissions }).then((value) => {
        this.setState({ submissionList: value })
      });
    }
  }

  handleSubmit() {
    if (document.getElementById('sortbytext').innerHTML !== 'Sort by') {
      const subredditNameInput = document.getElementById('subRedditNameInput')
      const numSubmissions = document.getElementById('numEntriesInput')
      this.setState({
        subReddit: subredditNameInput.value,
        numSubmissions: numSubmissions.value,
        submissionSubject: 'subreddit'
      }, function () {
        this.newQuery()
      });
      this.setState({ postHierarchy: document.getElementById('sortbytext').innerHTML })
    }
  }
  handleSubmitUser() {
    const userNameInput = document.getElementById('userInput')
    const numCommentsInput = document.getElementById('numCommentsValue')
    const numSubmissionsInput = document.getElementById('numSubmissionsValue')
    this.setState({
      user: userNameInput.value, //user
      numComments: parseInt(numCommentsInput.value), //comments
      numSubmissions: parseInt(numSubmissionsInput.value), //submissions
      submissionSubject: 'user'
    }, function () {
      this.newQuery()
    });
  }

  handleAggregate(e) {
    document.getElementById('sortbytext').innerHTML = e
  }

  toggleUserCards() {
    document.getElementById("subclick").classList.toggle("font-weight-bold");
    document.getElementById("commentclick").classList.toggle("font-weight-bold");
    if ($('#displayNumEntries').text() === String(this.state.numSubmissions)) {
      $('#displayNumEntries').text(String(this.state.numComments))
    }
    else {
      $('#displayNumEntries').text(String(this.state.numSubmissions))
    }
    $("#userSubmissions").toggle()
    $("#userComments").toggle()
  }

  showUserComments() {
  }
  //generates card objects based on return type from reddit api
  generateCards() {
    var sentiment = new Sentiment();
    const numSubmissions = this.state.submissionList.length
    let cards = [];
    let submissionTitle;
    let submissionBody;
    let submissionAuthor;
    let submissionSubreddit;
    var i = 0;
    if (numSubmissions > 0) {
      while (i < this.state.submissionList.length) {
        if (this.state.submissionList[i].is_self) {
          submissionBody = this.state.submissionList[i].selftext_html
          submissionTitle = this.state.submissionList[i].title
          submissionAuthor = this.state.submissionList[i].author.name
          submissionSubreddit = this.state.submissionList[i].subreddit_name_prefixed
        }
        else {
          submissionBody = this.state.submissionList[i].url
          submissionTitle = this.state.submissionList[i].title
          submissionAuthor = this.state.submissionList[i].author.name
          submissionSubreddit = this.state.submissionList[i].subreddit_name_prefixed
        }
        //issue with user submissions, sometimes self text body is null. this is a patch
        if (submissionBody === null) {
          submissionBody = '-no body found-'
        }

        const card = [submissionTitle,
          submissionBody,
          submissionAuthor,
          submissionSubreddit,
          sentiment.analyze(submissionTitle),
          sentiment.analyze(submissionBody)
        ]
        cards.push(card)

        i++
      }
    }
    else {
      cards.push(['loading...',
        'loading...',
        'loading...',
        'loading...',
        0,
        0
      ])
    }
    return cards
  }
  generateCommentCards() {
    var sentiment = new Sentiment();
    const numComments = this.state.commentList.length
    let cards = [];
    let commentTitle;
    let commentBody;
    let commentAuthor;
    let commentSubreddit;
    var i = 0;
    if (numComments > 0) {
      while (i < this.state.commentList.length) {
        commentBody = this.state.commentList[i].body
        commentTitle = this.state.commentList[i].link_title
        commentAuthor = this.state.commentList[i].link_author
        commentSubreddit = this.state.commentList[i].subreddit_name_prefixed

        cards.push([commentTitle,
          commentBody,
          commentAuthor,
          commentSubreddit,
          sentiment.analyze(commentTitle),
          sentiment.analyze(commentBody)

        ])
        i++
      }
    }
    else {
      cards.push(['loading...',
        'loading...',
        'loading...',
        'loading...',
        0,
        0
      ])
    }

    return cards
  }

  render() {

    const classDef = 'container-fluid my-3 bg-dark text-light border border-dark rounded'
    const submissionCards = <div id='userSubmissions'>{this.generateCards().map((element, index) => <Card data={element} key={index} />)}</div>
    const commentCards = <div id='userComments' style={{ display: 'none' }}>{this.generateCommentCards().map((element, index) => <Card data={element} key={index} />)}</div>
    return (
      <div>
        <div>
          <Navbar bg="dark" variant="dark">
            <Navbar.Brand href="#home">
              <img
                alt=""
                src={logo}
                width="30"
                height="30"
                className="d-inline-block align-top"
              />{' '}
              Reddit Sentiment Analysis
            </Navbar.Brand>
          </Navbar>
        </div>
        <div className='container-fluid '>

          <div className='row text-light top15 '>
            <div className='col-4 d-flex align-items-stretch'>
              <div className='card bg-dark'>
                <div className='card-body'>
                  <h5 className='font-weight-bold'>App in Brief</h5>
                    This app's purpose is to illustrate the overall feeling of subsets of Reddit posts.
                  Using output from <a href='https://www.npmjs.com/package/sentiment'>Sentiment</a>, the program assigns a score to a typed phrase by comparing each word to a dictionary of (word, score) pairs.
                  The library returns the summed score of each word in a phrase, which is shown beside its respective domain.
                </div>
              </div>
            </div>
            <div className='col-4 d-flex align-items-stretch'>
              <div className='card bg-dark'>
                <div className='card-body'>
                  <h5><span className='font-weight-bold'>Search by Subreddit</span></h5>
                  <SearchDetails subReddit={this.state.subReddit} postHierarchy={this.state.postHierarchy} handleAggregate={this.handleAggregate} handleSubmit={this.handleSubmit} />
                </div>
              </div>
            </div>
            <div className='col-4 d-flex align-items-stretch'>

              <div className='card bg-dark'>
                <div className='card-body'>
                  <h5><span className='font-weight-bold'>Search by User</span></h5>
                  <UserSearchDetails user={this.state.user} handleSubmitUser={this.handleSubmitUser} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='container-fluid'>
          <div className={classDef}>
            <div className='row'>
              <div className='col-6'>

                {this.state.submissionSubject === 'subreddit' && <h3 className='top10'>r/{this.state.subReddit}</h3>}
                {this.state.submissionSubject === 'subreddit' && <div className='text-sm'>{this.state.postHierarchy} | {this.state.submissionList.length} submissions<hr /></div>}
                {this.state.submissionSubject === 'subreddit' && <div>{submissionCards}</div>}

                {this.state.submissionSubject === 'user' && <h3 className='top10'>u/{this.state.user}</h3>}
                {this.state.submissionSubject === 'user' &&
                  <span className='text-sm hovertext'> <span id='displayNumEntries'>{this.state.numSubmissions}</span> <span id='subclick' className='font-weight-bold' onClick={this.toggleUserCards}>submissions</span> | <span id='commentclick' onClick={this.toggleUserCards}>comments</span></span>}
                {
                  this.state.submissionSubject === 'user' &&
                  <div>
                    <hr />
                    {submissionCards}
                    {commentCards}
                  </div>
                }
              </div>
              <div className='col-6'>
                <center><h3 className='top10'>Sentiment Charts</h3></center>
                <hr />


                <div className='container rounded chart-cont border border-secondary'>
                  <BarChart
                    color="#2c456e"
                    data={this.generateCards()}
                    index={4}
                    title='Post-Title Sentiment Frequency'
                    label=''
                    chartType='single'
                  />
                </div>
                <br />
                <div className='container rounded chart-cont border border-secondary'>
                  <PieChart
                    data={this.generateCards()}
                    index={4}
                    title='Post-Title Net Sentiment'
                  />
                </div>
                <br />
                <div className='container rounded chart-cont border border-secondary'>
                  <BarChart
                    color="#2c456e"
                    data={this.generateCards()}
                    index={5}
                    title='Post-Body Sentiment Frequency'
                    label=''
                    chartType='single'
                  />
                </div>
                <br />

                <div className='container rounded chart-cont border border-secondary'>
                  <PieChart
                    data={this.generateCards()}
                    index={5}
                    title='Post-Body Net Sentiment'
                  />
                </div>
                <br />
                <br />
              </div>
            </div>
          </div >
        </div>
      </div>
    )
  }
}

export default App;

