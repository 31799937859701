import React from "react";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Dropdown from 'react-bootstrap/DropDown';

export default class SearchDetails extends React.Component {

    render() {
        return (
            <div className='form-inline top15'>
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">r/</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        id="subRedditNameInput"
                        defaultValue={this.props.subReddit}
                        aria-label="subreddit"
                        aria-describedby="basic-addon1"
                    />
                </InputGroup>
                &nbsp;
                <InputGroup className="mb-3">
                    <FormControl
                        id="numEntriesInput"
                        defaultValue='30'
                        aria-label="numentries"
                        aria-describedby="basic-addon2"
                        type="number"
                        min="0"
                        className='inputCustom '
                    />
                    <InputGroup.Append>
                        <InputGroup.Text id="basic-addon2">entries</InputGroup.Text>
                    </InputGroup.Append>
                </InputGroup>
                    &nbsp;
                <InputGroup className="mb-3">
                    <Dropdown as={ButtonGroup} onSelect={this.props.handleAggregate}>
                        <Button className='btn btn-outline-primary' id='sortbytext'>{this.props.postHierarchy}</Button>
                        <Dropdown.Toggle className='btn btn-outline-primary' split id="dropdown-split-basic" />

                        <Dropdown.Menu>
                            <Dropdown.Item eventKey="top">top</Dropdown.Item>
                            <Dropdown.Item eventKey="new">new</Dropdown.Item>
                            <Dropdown.Item eventKey="controversial">controversial</Dropdown.Item>
                            <Dropdown.Item eventKey="rising">rising</Dropdown.Item>
                            <Dropdown.Item eventKey="hot">hot</Dropdown.Item>
                        </Dropdown.Menu>
                        &nbsp;
                    </Dropdown>
                    <Button className='btn btn-outline-primary' type='button' onClick={this.props.handleSubmit}>Submit</Button>
                </InputGroup>





            </div>
        )
    }
}

