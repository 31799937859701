import React from "react";
import Chart from 'chart.js';

export default class BarChart extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidUpdate() {

    if (this.props.data[0] !== 'loading...') {

      var d = this.helper()

      if (this.props.chartType === 'single') {
        this.myChart.data.datasets[0].data = d.frequency;
        this.myChart.options.scales.yAxes.ticks = { max: Math.max(d.frequency) }
        this.myChart.data.labels = d.sentiments;
      }
      else {
        this.myChart.data.datasets[0].data = d.frequency;
        this.myChart.data.datasets[1].data = d.frequency2;
        this.myChart.data.labels[0] = d.sentiments;
        this.myChart.data.labels[1] = d.sentiments2;
      }

      this.myChart.update();
    }
  }

  helper() {
    const scores = this.props.data
    const index = this.props.index
    const index2 = this.props.index2
    var placeholder = [];
    var placeholder2 = [];
    var i = 0

    if (this.props.chartType === 'single') {
      while (i < scores.length) {
        placeholder.push(scores[i][index].score)
        i += 1
      }
    }
    else {
      while (i < scores.length) {
        placeholder.push(scores[i][index].score)
        i += 1
      }
      i = 0;
      while (i < scores.length) {
        placeholder2.push(scores[i][index2].score)
        i += 1
      }
    }

    if (this.props.chartType === 'single') {
      placeholder = placeholder.sort((a, b) => a - b);
      let inter = this.helperSubroutine(placeholder)
      let a = inter.sent
      let b = inter.freq
      return { sentiments: a, frequency: b }
    }
    else {
      placeholder = placeholder.sort((a, b) => a - b);
      placeholder2 = placeholder2.sort((a, b) => a - b);
      let inter = this.helperSubroutine(placeholder)
      let a = inter.sent
      let b = inter.freq
      inter = this.helperSubroutine(placeholder2)
      let c = inter.sent
      let d = inter.freq
      let returnval = {
        sentiments: a, frequency: b,
        sentiments2: c, frequency2: d
      }
      return returnval
    }
  }


  helperSubroutine(array) {
    var a = [],
      b = [],
      prev;
    for (let i = 0; i < array.length; i++) {
      if (array[i] !== prev) {
        a.push(array[i]);
        b.push(1);
      } else {
        b[b.length - 1]++;
      }
      prev = array[i];
    }
    return { sent: a, freq: b }
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  componentDidMount() {
    var dataToDisplay = [];
    if (this.props.chartType === 'single') {
      dataToDisplay = {
        labels: ['loading...'],
        datasets: [{
          label: 'Num Posts',
          data: [100],
          backgroundColor: this.props.color
        }]
      }
    }
    if (this.props.chartType === 'stacked') {
      dataToDisplay = {
        labels: ['loading...'],
        datasets: [{
          label: 'Post Body',
          data: [100],
          backgroundColor: this.props.color
        },
        {
          label: 'Post Title',
          data: [50],
          backgroundColor: this.props.color2
        }]
      }
    }
    this.myChart = new Chart(this.canvasRef.current, {
      type: 'bar',
      options: {
        legend: {
          display: false
        },
        title: {
          display: true,
          text: this.props.title,
          fontSize:16
        },
        maintainAspectRatio: true,
        scales: {
          xAxes: [
            {
              scaleLabel: { display: true, labelString: "Net Sentiment", fontSize:14},
              ticks: { min: -10, max: 10, fontSize:12},
            }
          ],
          yAxes: [
            {
              scaleLabel: { display: true, labelString: "Frequency", fontSize:14},
              ticks: { min: 0, fontSize:12, fontStyle: "bold"}
            }
          ]
        }
      },
      data: dataToDisplay
    });
  }

  render() {
    return (
      <canvas ref={this.canvasRef} />
    );
  }
}