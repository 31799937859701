import React from 'react';
import Markup from 'interweave';


export default class Card extends React.Component {

  render() {
    const title = this.props.data[0]
    const body = this.props.data[1]
    const user = this.props.data[2]
    const subreddit = this.props.data[3]
    const titleFeel = this.props.data[4]
    const bodyFeel = this.props.data[5]
    return (
      <div className='container rounded chart-card floating hoverDiv border border-dark bottom15'>
        <p  className='bottom7' />

        {/* title */}
        <h5 className='top10' style={{ display: 'inline' }}>{title} {titleFeel.score >= 0 ? <b className='text-success'>[{titleFeel.score}]</b> : <b className='text-danger'>[{titleFeel.score}]</b>}</h5>
        <br />
        {/* user/subreddit */}
        {'u/' + user}&nbsp;|&nbsp;{subreddit}
        <hr />
        {/* body */}
        <h6 className='wrapped' style={{ display: 'inline' }}>
          {body.startsWith('http') ? <a href={body} >{body}</a> : <Markup content={body} />}
        </h6>
        {bodyFeel.score >= 0 ? <b className='text-success'> &nbsp;[{bodyFeel.score}]</b> : <b className='text-danger'> &nbsp;[{bodyFeel.score}]</b>}
        {body.includes('jpg') || body.includes('png') ? <img src={body} alt="loading..." /> : ''}
        <p className='bottom7' />
      </div>
    )
  }
}

