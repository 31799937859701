import React from "react";
import Chart from 'chart.js';

export default class PieChart extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidUpdate() {
    if (this.props.data[0] !== 'loading...') {
      let arr = this.helper()
      let positiveNumbersCount = arr.filter(v => v > 0).length;
      let negativeNumbersCount = arr.filter(v => v < 0).length;
      let newDataSet = [positiveNumbersCount,negativeNumbersCount]
      this.myChart.data.datasets[0].data = newDataSet
      this.myChart.update();
    };
  }

  helper() {
    let index = this.props.index
    let scores = this.props.data
    let placeholder = []
    let i=0
    while (i < scores.length) {
      placeholder.push(scores[i][index].score)
      i += 1
    }
    return placeholder
  }

  componentDidMount() {

    this.myChart = new Chart(this.canvasRef.current, {
      type: 'pie',
      data: {
        labels: ["Positive", 'Negative'],
        datasets: [{
          label: "Sentiment",
          backgroundColor: ['#28a745', "#dc3545"],
          data: [1, 1]
        }]
      },
      options: {
        title: {
          display: true,
          text: this.props.title,
          fontSize: 16
        }
      }
    });
  }
  render() {
    return (
      <canvas ref={this.canvasRef} />
    );
  }
}