import React from "react";
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

export default class UserSearchDetails extends React.Component {
    render() {
        return (
            <div className='form-inline top15'>
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">u/</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        id="userInput"
                        defaultValue={this.props.user}
                        aria-label="user"
                        aria-describedby="basic-addon1"
                    />
                </InputGroup>
                &nbsp;
                <InputGroup className="mb-3" id='numSubmissions'>
                    <FormControl
                        id="numSubmissionsValue"
                        defaultValue='10'
                        aria-label="numentries"
                        aria-describedby="basic-addon3"
                        type="number"
                        min="0"
                        className='inputCustom'
                    />
                    <InputGroup.Append>
                        <InputGroup.Text id="basic-addon4" onClick={this.hideSubmissionInput}>submissions</InputGroup.Text>
                    </InputGroup.Append>
                </InputGroup>
                &nbsp;
                <InputGroup className="mb-3" id='numComments'>
                    <FormControl
                        id="numCommentsValue"
                        defaultValue='20'
                        aria-label="numentries"
                        aria-describedby="basic-addon2"
                        type="number"
                        min="0"
                        className='inputCustom'
                    />
                    <InputGroup.Append>
                        <InputGroup.Text id="basic-addon2">comments</InputGroup.Text>
                    </InputGroup.Append>
                </InputGroup>
                    &nbsp;
                
                <InputGroup className="mb-3">
                    <Button className='btn btn-outline-primary' type='button' onClick={this.props.handleSubmitUser}>Submit</Button>
                </InputGroup>
            </div>
        )
    }
}
